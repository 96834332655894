import React from 'react';

export default function IndexSectionStats6() {
    return (
        <React.Fragment>
            <>
                <section className="py-20 lg:py-40 bg-black">
  <div className="container px-4 mx-auto">
    <div className="flex flex-wrap -mx-4">
      <div className="w-full xl:w-1/2 px-4 mb-20 xl:mb-0">
        <div className="max-w-md mx-auto">
          <div className="inline-flex items-center px-5 py-2 bg-white rounded-full mb-8">
            <span className="block w-2 h-2 mr-2 rounded-full bg-blue-500" />
            <span className="uppercase text-sm font-medium tracking-tighter">JUSTICE</span>
          </div>
          <h1 className="text-5xl sm:text-7xl xl:text-8xl font-heading font-semibold text-white mb-10">Are you willing to risk it?</h1>
          <div className="max-w-sm mb-18">
            <p className="text-lg text-gray-200 mb-10">Every arrest in America is subject to thousands of unique variables – from the friendliness of an officer to speed of legal aid.</p>
          </div>
        </div>
      </div>
      <div className="w-full xl:w-1/2 px-4">
        <div className="max-w-2xl mx-auto">
          <div className="flex flex-wrap -mx-4 -mb-8">
            <div className="w-full sm:w-1/2 px-4 mb-8">
              <div className="h-full px-8 py-14 xs:px-14 bg-purple-900 bg-opacity-20 rounded-4xl border-2 border-purple-900">
                <h4 className="text-7xl font-heading text-white mb-8">40</h4>
                <p className="text-xl text-white">incarcerated people in Oregon waiting for public defenders, on any given day</p>
              </div>
            </div>
            <div className="w-full sm:w-1/2 px-4 mb-8">
              <div className="h-full px-8 py-14 xs:px-14 bg-gray-900 rounded-4xl border-2 border-gray-700">
                <h4 className="text-7xl font-heading text-white mb-8">32%</h4>
                <p className="text-xl text-white">chance of an American experiencing arrest by the age of 28</p>
              </div>
            </div>
            <div className="w-full sm:w-1/2 px-4 mb-8">
              <div className="h-full px-8 py-14 xs:px-14 bg-gray-900 rounded-4xl border-2 border-gray-700">
                <h4 className="text-7xl font-heading text-white mb-8">27%</h4>
                <p className="text-xl text-white">more likely to be released pre-trial with a private attorney over a public defender</p>
              </div>
            </div>
            <div className="w-full sm:w-1/2 px-4 mb-8">
              <div className="h-full px-8 py-14 xs:px-14 bg-gray-900 rounded-4xl border-2 border-gray-700">
                <h4 className="text-7xl font-heading text-white mb-8">50%</h4>
                <p className="text-xl text-white">of arrested individuals can't recall their Miranda rights</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

