import React from 'react';

export default function IndexSectionNavigations4() {
    return (
        <React.Fragment>
            <>
                <section className="bg-black ">
  <nav>
    <div className="flex py-6 px-4 md:px-14 items-center justify-center">
      <a className="inline-block text-2xl font-bold" href="#">
        <img src="https://static.shuffle.dev/uploads/files/63/6370d1cc429b777321b8fdd593f5fc4da5de4d8d/mirandacall.svg" alt className="max-h-10" />
      </a>
    </div>
  </nav>
  <div className="hidden navbar-menu fixed top-0 left-0 bottom-0 w-5/6 max-w-sm z-50">
    <div className="navbar-backdrop fixed inset-0 backdrop-blur-xl backdrop-filter bg-gray-900 bg-opacity-80" />
    <nav className="relative pt-8 pb-8 bg-white h-full overflow-y-auto">
      <div className="flex flex-col px-6 h-full">
        <a className="inline-block text-2xl font-bold ml-8 mb-16" href="#">
          <img src="suncealand-assets/logos/suncealand-dark.svg" alt />
        </a>
        <ul className="w-full mb-auto pb-16">
          <li className="group mb-6">
            <button className="inline-block text-left text-base font-medium text-black">
              <div className="flex items-center">
                <span className="mr-3">Resources</span>
                <svg width={12} height={7} viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.332 0.999974L10.3887 0.0566406L6.66536 3.77997L5.9987 4.5L5.33203 3.77997L1.6087 0.0566402L0.665365 0.999974L5.9987 6.33331L11.332 0.999974Z" fill="currentColor" />
                </svg>
              </div>
            </button>
            <div className="hidden group-hover:block pt-4">
              <div className="pl-6"><a className="block mb-2 text-black" href="#">Link 1</a><a className="block mb-2 text-black" href="#">Link 1</a><a className="block text-black" href="#">Link 1</a></div>
            </div>
          </li>
          <li className="mb-6"><a className="inline-block text-base font-medium text-black" href="#">Community</a></li>
          <li className="mb-6"><a className="inline-block text-base font-medium text-black" href="#">Story</a></li>
          <li><a className="inline-block text-base font-medium text-black" href="#">Pricing</a></li>
        </ul>
        <div className="w-full">
          <a className="relative group inline-flex h-12 w-full mb-4 items-center justify-center font-semibold rounded-lg bg-white border border-black" href="#">
            <span className="text-black">Login</span>
          </a>
          <a className="relative inline-flex h-12 w-full mb-4 items-center justify-center font-semibold rounded-lg bg-blue-500" href="#">
            <span className="text-black">Logout</span>
          </a>
          <p className="pl-2 text-sm">2022 © Shuffle</p>
        </div>
      </div>
    </nav>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

