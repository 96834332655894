import React from 'react';

export default function IndexSectionStats3() {
    return (
        <React.Fragment>
            <>
                <section className="py-24 lg:py-40 bg-black">
  <div className="container px-4 mx-auto">
    <div className="flex flex-wrap -mx-4">
      <div className="w-full xl:w-1/3 px-4 mb-12 xl:mb-0">
        <div className="max-w-sm mx-auto">
          <div className="flex flex-wrap">
            <div className="w-full sm:w-2/5 mb-6 sm:mb-0">
              <span className="text-10xl font-heading text-yellow-100">1</span>
            </div>
            <div className="w-full sm:w-3/5 sm:pl-12 sm:border-l border-gray-800">
              <p className="text-lg text-gray-200">Request a phone call from your arrest officers. Dial the hotline with your personal code.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full xl:w-1/3 px-4 mb-12 xl:mb-0">
        <div className="max-w-sm mx-auto">
          <div className="flex flex-wrap">
            <div className="w-full sm:w-2/5 mb-6 sm:mb-0">
              <span className="text-10xl font-heading text-yellow-100">2</span>
            </div>
            <div className="w-full sm:w-3/5 sm:pl-12 sm:border-l border-gray-800">
              <p className="text-lg text-gray-200">Explain your situation in detail. Confirm if loved ones should be informed.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full xl:w-1/3 px-4">
        <div className="max-w-sm mx-auto">
          <div className="flex flex-wrap">
            <div className="w-full sm:w-2/5 mb-6 sm:mb-0">
              <div>
                <span className="text-10xl font-heading text-yellow-100">3</span>
              </div>
            </div>
            <div className="w-full sm:w-3/5 sm:pl-12 sm:border-l border-gray-800">
              <p className="text-lg text-gray-200">Wait while lawyers and bondsmen local to your detention are notified with loved ones.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

