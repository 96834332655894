import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import IndexSectionNavigations4 from "../components/navigations/IndexSectionNavigations4";
import IndexSectionHeaders10 from "../components/headers/IndexSectionHeaders10";
import IndexSectionStats3 from "../components/stats/IndexSectionStats3";
import IndexSectionFeatures5 from "../components/features/IndexSectionFeatures5";
import IndexSectionStats6 from "../components/stats/IndexSectionStats6";
import IndexSectionFeatures9 from "../components/features/IndexSectionFeatures9";

const meta = {
	title: "",
	meta: [],
	link: [],
	style: [],
	script: [],
};

export default function Index() {
	return (
		<html className="bg-black">
			<body className="bg-black">
				<React.Fragment>
					<HelmetProvider>
						<Helmet {...meta}></Helmet>
					</HelmetProvider>
					<IndexSectionNavigations4 />
					<IndexSectionHeaders10 />
					<IndexSectionStats3 />
					<IndexSectionFeatures5 />
					<IndexSectionStats6 />
					<IndexSectionFeatures9 />
				</React.Fragment>
			</body>
		</html>
	);
}
