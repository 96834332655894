import React from 'react';

export default function IndexSectionFeatures5() {
    return (
        <React.Fragment>
            <>
                <section className="pt-20 md:pt-40 overflow-hidden bg-black">
  <div className="container px-4 mx-auto mb-20 xs:mb-32">
    <div className="text-center mb-24 md:mb-32">
      <div className="inline-flex items-center mb-8">
        <span className="block w-2 h-2 mr-2 rounded-full bg-blue-500" />
        <span className="uppercase text-sm text-gray-200 font-medium">NO DOWNLOADS</span>
      </div>
      <h1 className="text-5xl sm:text-7xl md:text-8xl font-heading font-semibold text-white">One phone call.</h1>
    </div>
    <div className="flex flex-wrap items-center xl:items-start -mx-4">
      <div className="w-full lg:w-1/3 xl:w-1/4 px-4 mb-20 lg:mb-0">
        <div className="max-w-sm mx-auto">
          <div className="flex mb-24">
            <span className="flex-shrink-0 inline-block mt-4 mr-8 w-3 h-3 bg-yellow-500 rounded-full" />
            <div>
              <h4 className="font-heading text-3xl xs:text-4xl text-white font-semibold mb-8">Instant legal aid</h4>
              <p className="text-gray-300">We reach out to appropriate attorneys for you, with experience in your case type.</p>
            </div>
          </div>
          <div className="flex">
            <span className="flex-shrink-0 inline-block mt-4 mr-8 w-3 h-3 bg-yellow-500 rounded-full" />
            <div>
              <h4 className="font-heading text-3xl xs:text-4xl text-white font-semibold mb-8">Loved ones</h4>
              <p className="text-gray-300">Immediately notify those who matter the most, and enable direct contact between them and a lawyer.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full lg:w-1/3 xl:w-2/4 px-4 mb-20 lg:mb-0">
        <div>
          <img className="w-full max-w-md xl:max-w-lg mx-auto" src="https://static.shuffle.dev/uploads/files/63/6370d1cc429b777321b8fdd593f5fc4da5de4d8d/Group-9.png" alt />
        </div>
      </div>
      <div className="w-full lg:w-1/3 xl:w-1/4 px-4">
        <div className="max-w-sm mx-auto">
          <div className="flex mb-24">
            <span className="flex-shrink-0 inline-block mt-4 mr-8 w-3 h-3 bg-yellow-500 rounded-full" />
            <div>
              <h4 className="font-heading text-3xl xs:text-4xl text-white font-semibold mb-8">Any U.S. state</h4>
              <p className="text-gray-300">Miranda automatically detects where you're incarcerated, and is aware of the arrest procedures of your jurisdiction in any U.S. state.</p>
            </div>
          </div>
          <div className="flex">
            <span className="flex-shrink-0 inline-block mt-4 mr-8 w-3 h-3 bg-yellow-500 rounded-full" />
            <div>
              <h4 className="font-heading text-3xl xs:text-4xl text-white font-semibold mb-8">Know your rights</h4>
              <p className="text-gray-300">MirandaCall provides lawyer-vetted answers to your immediate questions.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

