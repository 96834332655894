import React from "react";

export default function IndexSectionHeaders10() {
	return (
		<React.Fragment>
			<>
				<section className="relative xl:pb-52 bg-black overflow-hidden">
					<div className="hidden xl:block absolute bottom-0 left-0 ml-20 2xl:ml-52 px-20">
						<img
							className="animate-bouncingSlow max-w-sm xl:max-w-md -mb-20"
							src="suncealand-assets/background/red-lines.svg"
							alt
						/>
						<img
							className="absolute top-0 left-1/2 mt-20 transform -translate-x-1/2"
							src="https://static.shuffle.dev/uploads/files/63/6370d1cc429b777321b8fdd593f5fc4da5de4d8d/Group-9.png"
							alt
						/>
					</div>
					<div className="container px-4 mx-auto">
						<div className="relative pt-28 xl:pt-52 mb-24 xl:mb-0">
							<div className="max-w-lg mx-auto xl:mr-0 relative">
								<div className="inline-flex items-center mb-8">
									<span className="block w-2 h-2 mr-2 rounded-full bg-indigo-500" />
									<span className="uppercase text-sm text-gray-100 font-medium tracking-tight">
										<span style={{ letterSpacing: "-0.30016px" }}>
											24/7 LEGAL FAILSAFE
										</span>
									</span>
								</div>
								<h1 className="text-6xl sm:text-8xl xl:text-9xl font-heading text-white mb-12" />
								<h1 className="text-6xl sm:text-8xl font-heading text-white mb-12">
									Prepared for any arrest
								</h1>
								<p className="text-lg text-gray-100 mb-20">
									Your dedicated hotline # for finding the perfect lawyer,
									informing loved ones, and verifying your rights – in any U.S.
									jurisdiction.
								</p>
							</div>
						</div>
						<div className="xl:hidden relative">
							<img
								className="animate-bouncingSlow h-135 mx-auto -mb-12"
								src="suncealand-assets/background/red-lines.svg"
								alt
							/>
							<img
								className="absolute top-0 left-1/2 w-full xs:max-w-md transform -translate-x-1/2"
								src="https://static.shuffle.dev/uploads/files/63/6370d1cc429b777321b8fdd593f5fc4da5de4d8d/Group-9.png"
								alt
							/>
						</div>
					</div>
					<div className="hidden navbar-menu fixed top-0 left-0 bottom-0 w-5/6 max-w-sm z-50">
						<div className="navbar-backdrop fixed inset-0 backdrop-blur-xl backdrop-filter bg-gray-900 bg-opacity-80" />
						<nav className="relative pt-8 pb-8 bg-white h-full overflow-y-auto">
							<div className="flex flex-col px-6 h-full">
								<a
									className="inline-block text-2xl font-bold ml-8 mb-16"
									href="#">
									<img src="suncealand-assets/logos/suncealand-dark.svg" alt />
								</a>
								<ul className="w-full mb-auto pb-16">
									<li className="group mb-6">
										<button className="inline-block text-left text-base font-medium text-black">
											<div className="flex items-center">
												<span className="mr-3">Resources</span>
												<svg
													width={12}
													height={7}
													viewBox="0 0 12 7"
													fill="none"
													xmlns="http://www.w3.org/2000/svg">
													<path
														d="M11.332 0.999974L10.3887 0.0566406L6.66536 3.77997L5.9987 4.5L5.33203 3.77997L1.6087 0.0566402L0.665365 0.999974L5.9987 6.33331L11.332 0.999974Z"
														fill="currentColor"
													/>
												</svg>
											</div>
										</button>
										<div className="hidden group-hover:block pt-4">
											<div className="pl-6">
												<a className="block mb-2 text-black" href="#">
													Link 1
												</a>
												<a className="block mb-2 text-black" href="#">
													Link 1
												</a>
												<a className="block text-black" href="#">
													Link 1
												</a>
											</div>
										</div>
									</li>
									<li className="mb-6">
										<a
											className="inline-block text-base font-medium text-black"
											href="#">
											Community
										</a>
									</li>
									<li className="mb-6">
										<a
											className="inline-block text-base font-medium text-black"
											href="#">
											Story
										</a>
									</li>
									<li>
										<a
											className="inline-block text-base font-medium text-black"
											href="#">
											Pricing
										</a>
									</li>
								</ul>
								<div className="w-full">
									<a
										className="relative group inline-flex h-12 w-full mb-4 items-center justify-center font-semibold rounded-lg bg-white border border-black"
										href="#">
										<span className="text-black">Login</span>
									</a>
									<a
										className="relative inline-flex h-12 w-full mb-4 items-center justify-center font-semibold rounded-lg bg-blue-500"
										href="#">
										<span className="text-black">Logout</span>
									</a>
									<p className="pl-2 text-sm">2022 © Shuffle</p>
								</div>
							</div>
						</nav>
					</div>
				</section>
			</>
		</React.Fragment>
	);
}
